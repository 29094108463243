import { Dialog, DialogContent } from "@/components/shadcn/dialog.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { useEffect, useState } from "react";
import {
  PlanDetail,
  plans,
  PRO_PLAN,
  STANDARD_PLAN,
} from "@/utils/data/plans.ts";
import UpgradeBox from "@/components/UserProfileDialog.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/shadcn/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/radio.tsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircle } from "@mynaui/icons-react";
import { PermissionsData } from "@/routes/feeds.tsx";
import { CheckoutUpgradeSubscription } from "@/components/CheckoutSubscriptionPlan.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";
import { Loader } from "@/components/custom-components/Loader";

export default function FreeTrialEnding({
  open,
  onOpenChange,
  permissionData,
}: {
  open: boolean;
  onOpenChange: () => void;
  permissionData: PermissionsData;
}) {
  const navigate = useNavigate();

  const [subscriptionStatus, setSubscriptionStatus] = useState<
    "onFreeTrial" | "hasPlan" | "hasNoPlan" | undefined
  >(undefined);
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined,
  );

  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  const [newSelectedPlan, setNewSelectedPlan] = useState<
    PlanDetail | undefined
  >(undefined);

  const { data: chargebeeSyncStatus, isLoading: isLoadingChargebeeSync } =
    trpc.getIsUserSynced.useQuery(undefined, {
      enabled: open,
    });

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  const { mutateAsync: logoutUser, isPending: loggingOut } =
    trpc.logoutUser.useMutation();

  useEffect(() => {
    if (!isLoadingChargebeeSync && !chargebeeSyncStatus) {
      navigate({ to: "/checkout" });
    }
  }, [chargebeeSyncStatus]);

  useEffect(() => {
    if (permissionData && plans) {
      const defaultPlan = plans[1].plan_id;

      if (
        permissionData.userCanAccessEverything &&
        permissionData.usersPlans.length === 0
      )
        setSubscriptionStatus("onFreeTrial");
      else if (
        permissionData.usersPlans.length > 0 &&
        permissionData.usersPlans.some((plan) =>
          [
            STANDARD_PLAN.plan_id,
            STANDARD_PLAN.no_trial_plan_id,
            PRO_PLAN.plan_id,
            PRO_PLAN.no_trial_plan_id,
          ].includes(plan),
        )
      ) {
        setSubscriptionStatus("hasPlan");
      } else {
        setSubscriptionStatus("hasNoPlan");
        form.setValue("plan", defaultPlan);
        setSelectedPlan(defaultPlan);
      }
    }
  }, [permissionData]);

  const selectPlanFormSchema = z.object({
    plan: z.string({ required_error: "Select a plan" }),
  });

  type SelectPlanFormValues = z.infer<typeof selectPlanFormSchema>;

  const defaultValues: Partial<SelectPlanFormValues> = {};

  const form = useForm<SelectPlanFormValues>({
    resolver: zodResolver(selectPlanFormSchema),
    defaultValues,
  });

  async function onSubmit() {
    const selectedPlan = form.getValues("plan");

    if (selectedPlan) {
      setUpgradeOpen(true);
      setNewSelectedPlan(plans.find((p) => p.plan_id === selectedPlan));
    }
  }

  const plan = form.watch("plan");

  useEffect(() => {
    setNewSelectedPlan(plans.find((p) => p.plan_id === plan));
  }, [plan]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={`sm:max-w-lg overflow-y-auto max-h-screen ${subscriptionStatus === "hasNoPlan" ? "[&>button]:hidden" : ""}`}
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        {isLoadingChargebeeSync ? (
          <div
            className={
              "flex w-full justify-center items-center h-[20vh] m-auto"
            }
          >
            <Loader />
          </div>
        ) : upgradeOpen && newSelectedPlan ? (
          <CheckoutUpgradeSubscription
            userHasPlan={subscriptionStatus === "hasPlan"}
            selectedPlan={newSelectedPlan}
          />
        ) : (
          <div>
            <div>
              <div className={"flex flex-col gap-2"}>
                <h4
                  className={
                    "text-2xl text-left text-themeforeground font-semibold"
                  }
                >
                  Upgrade to Unlock
                </h4>
                <p className={"text-left text-thememutedforeground mb-6"}>
                  Level up your ad workflow with our paid plans
                </p>
              </div>
            </div>
            <div>
              <>
                {subscriptionStatus === "hasNoPlan" && (
                  <div className={"space-y-3 mb-6"}>
                    {plans &&
                      plans
                        .find((item) => item.plan_id === selectedPlan)
                        ?.features.map((feature, index) => (
                          <div
                            key={index}
                            className="flex gap-1 justify-start items-center text-thememutedforeground"
                          >
                            <CheckCircle className="w-4 h-4" />
                            <span className="text-sm font-normal leading-5 ">
                              {feature}
                            </span>
                          </div>
                        ))}
                  </div>
                )}

                {subscriptionStatus === "hasPlan" &&
                  permissionData.usersPlans.find(
                    (p) => p === STANDARD_PLAN.plan_id,
                  ) && (
                    <div className={"space-y-3 mb-6"}>
                      {PRO_PLAN.features.map((feature, index) => (
                        <div
                          key={index}
                          className="flex gap-1 justify-start items-center text-thememutedforeground"
                        >
                          <CheckCircle className="w-4 h-4" />
                          <span className="text-sm font-normal leading-5 ">
                            {feature}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
              </>
            </div>
            <div className="gap-3 flex flex-col">
              <div className={"flex justify-between items-center gap-1.5 h-9"}>
                <h5>Select your plan</h5>

                {subscriptionStatus === "hasNoPlan" &&
                  chargebeeSyncStatus &&
                  (fetchingPortalSessionUrl ? (
                    <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                      Loading...
                    </span>
                  ) : (
                    <div className="flex gap-4">
                      <span
                        onClick={() => openPortalSessionUrl()}
                        className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide"
                      >
                        Manage Account
                      </span>
                      <span
                        onClick={() => {
                          logoutUser().then(() => {
                            if (typeof localStorage !== "undefined") {
                              localStorage.clear();
                            }
                            navigate({ to: "/login" });
                          });
                        }}
                        className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide"
                      >
                        {loggingOut ? "Logging out..." : "Logout"}
                      </span>
                    </div>
                  ))}
              </div>
              {subscriptionStatus === "hasPlan" ? (
                <div className="gap-3 flex flex-col">
                  {plans &&
                    plans.map((plan, index) => (
                      <UpgradeBox
                        boxData={plan}
                        key={index}
                        userPlans={permissionData?.usersPlans ?? []}
                        descriptionDetailsToShow={"description"}
                        setSelected={setNewSelectedPlan}
                        setUpgradeOpen={setUpgradeOpen}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  <Form {...form}>
                    <form
                      onSubmit={form.handleSubmit(onSubmit)}
                      className="space-y-6"
                    >
                      <div className={"space-y-6"}>
                        <FormField
                          control={form.control}
                          name="plan"
                          render={({ field }) => (
                            <RadioGroup
                              onValueChange={field.onChange}
                              value={field.value}
                              className="flex flex-col space-y-1"
                            >
                              {plans &&
                                plans.map((item) => (
                                  <FormControl key={item.plan_id}>
                                    <FormItem>
                                      <RadioGroupItem
                                        checked={field.value === item.plan_id}
                                        value={item.plan_id}
                                        onChange={() =>
                                          field.onChange(item.plan_id)
                                        }
                                        id={item.name}
                                        plan={item}
                                        showTrial={false}
                                        onClick={() =>
                                          setSelectedPlan(item.plan_id)
                                        }
                                      >
                                        {item.name}
                                      </RadioGroupItem>
                                    </FormItem>
                                  </FormControl>
                                ))}
                            </RadioGroup>
                          )}
                        />
                        <Button
                          className={"w-full"}
                          variant={"destructivePurple"}
                          size={"lg"}
                          type={"submit"}
                        >
                          Upgrade
                        </Button>
                      </div>
                    </form>
                  </Form>
                </div>
              )}
            </div>

            <div className={"mt-3"}>
              <div className={"flex justify-center"}>
                <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                  <span className={"underline font-medium text-sm text-center"}>
                    View full plan comparison
                  </span>
                </a>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
