import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import {
  Like,
  Link as LinkIcon,
  Presentation,
  TrendingUp,
  X,
} from "@mynaui/icons-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { useState } from "react";
import { cn } from "@/lib/utils.ts";
import { trpc } from "@/utils/trpc.ts";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import BrowserFrame from "@/components/ad-inspiration/BrowserFrame.tsx";
import { EmailFeedContent } from "../../../server/services/emails/definitions";

const InspirationDetailsMediaSection = ({
  data,
}: {
  data: EmailFeedContent;
}) => (
  <div className=" flex flex-col my-12 mx-8 items-center">
    <div className={"flex justify-center"}>
      <div className={"w-full"}>
        <BrowserFrame />
        {/* <ScrollArea className={"h-[80svh]"}> */}
        <img
          alt={"screenshot of a landing page"}
          loading={"lazy"}
          className={"w-full rounded-b-lg"}
          src={data.imageUrl ?? ""}
        />
        {/* </ScrollArea> */}
      </div>
    </div>
  </div>
);

export default function EmailAdInspirationDetails({
  open,
  onOpenChange,
  data,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: EmailFeedContent;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();



  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const [adRequested, setAdRequested] = useState<boolean>(false);

  const { mutateAsync: requestAdTemplate } =
    trpc.requestLanderTemplate.useMutation();

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent
        onInteractOutside={(e) => e.preventDefault()}
        className={"h-[85vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
      >
        {accessPromptDialogOpen && (
          <FreeAccessPrompt
            open={accessPromptDialogOpen}
            onOpenChange={() => setAccessPromptDialogOpen(false)}
          />
        )}
        <ScrollArea
          className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
        >
          <InspirationDetailsMediaSection data={data} />
        </ScrollArea>
        <div className="hidden lg:flex">
          <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6 w-full">
              <div className={"flex gap-5 justify-between"}>
                <div className={`flex gap-[0.625rem] items-center`}>
                  <img
                    src={"/bg_gradient.jpg"}
                    alt={"Brand name"}
                    className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                  ></img>

                  <div>
                    <h4 className={`truncate font-[500] text-sm`}>Burrow</h4>
                    <p className={"text-xs text-thememutedforeground"}>
                      Currently running 50 ads
                    </p>
                  </div>
                </div>
                <div className={"flex gap-2"}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/landing-pages/${data.id}`
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-xl leading-7 text-themeforeground">
                  Burrow Social Ad
                </span>
                <div className="flex flex-row gap-4">
                  <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center">
                    <TrendingUp className="w-4 h-4" />
                    <span className="text-nowrap">Active for 27 Days</span>
                  </span>
                  <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center">
                    <Like className="w-4 h-4" />
                    <span className="text-nowrap">184 Requests</span>
                  </span>
                  <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center text-nowrap">
                    <Presentation className="w-4 h-4" />
                    Inspiration
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ScrollArea className="flex lg:hidden h-full">
          <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
              <div className={"flex gap-5 justify-between"}>


                <div className={"flex gap-2"}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/landing-pages/${data.id}`
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div className={""}>
                <p className={"font-semibold text-xl"}>
                  Landing Page
                </p>
              </div>

              <div className={"grid grid-cols-2 gap-3 "}>
                <Button
                  onClick={
                    !authUser
                      ? () => setAccessPromptDialogOpen(true)
                      : () => {
                        setAdRequested(true);
                        requestAdTemplate({
                          landerID: data.id,
                        }).then(() => {
                          showToastNotification("success", {
                            message: "Landing Template has been requested!",
                          });

                          setTimeout(() => {
                            setAdRequested(false);
                          }, 5000);
                        });
                      }
                  }
                >
                  {adRequested ? "Requested!" : "Request Template"}
                </Button>
                <a
                  href={"#"}
                  target={"_blank"}
                  className={cn(buttonVariants({ variant: "secondary" }))}
                >
                  View Landing Page
                </a>
              </div>
            </div>
          </div>
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
          >
            <InspirationDetailsMediaSection data={data} />
          </ScrollArea>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
