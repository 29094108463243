import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import {
  CalendarUp,
  Desktop,
  Like,
  Link as LinkIcon,
  Mobile,
  X,
} from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { useState } from "react";
import {
  LandingFeedProps,
  ManuallySaveFeedAdToBoard,
} from "@/components/templates/LandingAdCard";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/tabs.tsx";
import { cn } from "@/lib/utils.ts";
import { trpc } from "@/utils/trpc.ts";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import BrowserFrame from "@/components/ad-inspiration/BrowserFrame.tsx";

const InspirationDetailsMediaSection = ({
  data,
}: {
  data: LandingFeedProps;
}) => (
  <div className=" flex flex-col my-12 mx-8 items-center">
    <Tabs defaultValue={"desktop"}>
      <div className={"flex justify-center"}>
        <TabsList>
          <TabsTrigger
            value={"desktop"}
            className={"flex gap-1.5 items-center"}
          >
            <Desktop className={"w-4 h-4"} /> <span>Desktop</span>
          </TabsTrigger>
          <TabsTrigger value={"mobile"} className={"flex gap-1.5 items-center"}>
            <Mobile className={"w-4 h-4"} /> <span>Mobile</span>
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value={"desktop"}>
        {data.desktopScreenshot && (
          <div>
            <BrowserFrame />
            <ScrollArea className={"h-[80svh]"}>
              <img
                alt={"screenshot of a landing page"}
                loading={"lazy"}
                className={"w-full rounded-b-lg"}
                src={data.desktopScreenshot}
              />
            </ScrollArea>
          </div>
        )}
      </TabsContent>
      <TabsContent value={"mobile"}>
        {data.mobileScreenshot && (
          <div className={"flex justify-center"}>
            <div className={"w-[23.25rem]"}>
              <BrowserFrame />
              {/* <ScrollArea className={"h-[80svh]"}> */}
              <img
                alt={"screenshot of a landing page"}
                loading={"lazy"}
                className={"w-full rounded-b-lg"}
                src={data.mobileScreenshot}
              />
              {/* </ScrollArea> */}
            </div>
          </div>
        )}
      </TabsContent>
    </Tabs>
  </div>
);

export default function LanderAdInspirationDetails({
  open,
  onOpenChange,
  data,
  IsPublic,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: LandingFeedProps;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();

  // const { data: adCreativeDetails } = trpc.getAdCreativeDetails.useQuery(
  //   { adId: data.id },
  //   {
  //     enabled: open,
  //   },
  // );

  const { data: numberOfActiveAdsForBrand } =
    trpc.getNumberOfActiveAdsForBrand.useQuery(
      { brandId: data.brandId || "" },
      {
        enabled: open,
      },
    );

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const [adRequested, setAdRequested] = useState<boolean>(false);

  const { mutateAsync: requestAdTemplate } =
    trpc.requestLanderTemplate.useMutation();

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent
        onInteractOutside={(e) => e.preventDefault()}
        className={"h-[85vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
      >
        {accessPromptDialogOpen && (
          <FreeAccessPrompt
            open={accessPromptDialogOpen}
            onOpenChange={() => setAccessPromptDialogOpen(false)}
          />
        )}
        <ScrollArea
          className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
        >
          <InspirationDetailsMediaSection data={data} />
        </ScrollArea>
        <div className="hidden lg:flex">
          <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6 w-full">
              <div className={"flex gap-5 justify-between"}>
                <div className={`flex gap-[0.625rem] items-center`}>
                  {data.brandImage && data.brandId && (
                    <Link
                      to={`/feeds/brands/$brandID`}
                      params={{ brandID: data.brandId }}
                    >
                      <img
                        src={data.brandImage}
                        alt={data.brandName || "Brand name"}
                        className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                      ></img>
                    </Link>
                  )}
                  <div>
                    {data.brandName && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <h4 className={`truncate font-[500] text-sm`}>
                          {data.brandName}
                        </h4>
                      </Link>
                    )}
                    <p className={"text-xs text-thememutedforeground"}>
                      Currently running{" "}
                      {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                    </p>
                  </div>
                </div>

                <div className={"flex gap-2"}>
                  {!IsPublic && data && (
                    <ManuallySaveFeedAdToBoard
                      open={saveOpen}
                      onOpenChange={setSaveOpen}
                      data={data}
                      variant={"outline"}
                    />
                  )}

                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/landing-pages/${data.landerId}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div className={""}>
                <p className={"font-semibold text-xl"}>
                  <span>{data.brandName}</span> Landing Page
                </p>
                <div className={"flex gap-4 text-thememutedforeground text-sm"}>
                  <div className={"flex items-center gap-1"}>
                    <span>
                      <Like className={"w-4.5"} />
                    </span>
                    <span>{data?.requestCount || 0} Requests</span>
                  </div>
                </div>
              </div>

              <div className={"grid grid-cols-2 gap-3 "}>
                <Button
                  onClick={
                    !authUser
                      ? () => setAccessPromptDialogOpen(true)
                      : () => {
                          setAdRequested(true);
                          requestAdTemplate({
                            landerID: data.landerId,
                          }).then(() => {
                            showToastNotification("success", {
                              message: "Landing Template has been requested!",
                            });

                            setTimeout(() => {
                              setAdRequested(false);
                            }, 5000);
                          });
                        }
                  }
                >
                  {adRequested ? "Requested!" : "Request Template"}
                </Button>
                <a
                  href={data?.landerUrl}
                  target={"_blank"}
                  className={cn(buttonVariants({ variant: "secondary" }))}
                >
                  View Landing Page
                </a>
              </div>
              <div className={"flex flex-col gap-4 text-sm"}>
                <div className={"flex justify-between items-center gap-1"}>
                  <div className={"flex items-center gap-1.5"}>
                    <span>
                      <CalendarUp size={"1.2rem"} />
                    </span>
                    <span className={"font-medium"}>Published</span>
                  </div>
                  <div>
                    <span className={"text-thememutedforeground"}>
                      {data.createdAt &&
                        new Date(data.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollArea className="flex lg:hidden h-full">
          <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
              <div className={"flex gap-5 justify-between"}>
                <div className={`flex gap-[0.625rem] items-center`}>
                  {data.brandImage && data.brandId && (
                    <Link
                      to={`/feeds/brands/$brandID`}
                      params={{ brandID: data.brandId }}
                    >
                      <img
                        src={data.brandImage}
                        alt={data.brandName || "Brand name"}
                        className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                      ></img>
                    </Link>
                  )}
                  <div>
                    {data.brandName && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <h4 className={`truncate font-[500] text-sm`}>
                          {data.brandName}
                        </h4>
                      </Link>
                    )}
                    <p className={"text-xs text-thememutedforeground"}>
                      Currently running{" "}
                      {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                    </p>
                  </div>
                </div>

                <div className={"flex gap-2"}>
                  {!IsPublic && data && (
                    <ManuallySaveFeedAdToBoard
                      open={saveOpen}
                      onOpenChange={setSaveOpen}
                      data={data}
                      variant={"outline"}
                    />
                  )}

                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/landing-pages/${data.landerId}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div className={""}>
                <p className={"font-semibold text-xl"}>
                  <span>{data.brandName}</span> Landing Page
                </p>
              </div>

              <div className={"grid grid-cols-2 gap-3 "}>
                <Button
                  onClick={
                    !authUser
                      ? () => setAccessPromptDialogOpen(true)
                      : () => {
                          setAdRequested(true);
                          requestAdTemplate({
                            landerID: data.landerId,
                          }).then(() => {
                            showToastNotification("success", {
                              message: "Landing Template has been requested!",
                            });

                            setTimeout(() => {
                              setAdRequested(false);
                            }, 5000);
                          });
                        }
                  }
                >
                  {adRequested ? "Requested!" : "Request Template"}
                </Button>
                <a
                  href={data?.landerUrl}
                  target={"_blank"}
                  className={cn(buttonVariants({ variant: "secondary" }))}
                >
                  View Landing Page
                </a>
              </div>
              <div className={"flex flex-col gap-4 text-sm"}>
                <div className={"flex justify-between items-center gap-1"}>
                  <div className={"flex items-center gap-1.5"}>
                    <span>
                      <CalendarUp size={"1.2rem"} />
                    </span>
                    <span className={"font-medium"}>Published</span>
                  </div>
                  <div>
                    <span className={"text-thememutedforeground"}>
                      {data.createdAt &&
                        new Date(data.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
          >
            <InspirationDetailsMediaSection data={data} />
          </ScrollArea>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
