import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select.tsx";
import { cn } from "@/lib/utils.ts";
import { buttonVariants } from "@/components/shadcn/button.tsx";
import { useNavigate } from "@tanstack/react-router";

export const OrderFilterSelect = ({
  defaultFilter,
  options,
}: {
  defaultFilter: string;
  options: Array<string>;
}) => {
  const navigate = useNavigate();

  return (
    <Select
      onValueChange={(value) => {
        navigate({
          search: (old) => {
            return {
              ...old,
              cacheBuster: value === "Random" ? Math.random() : undefined,
              orderFilter: value,
            };
          },
          params: (old) => {
            return {
              ...old,
            };
          },
        });
      }}
    >
      <SelectTrigger
        className={cn(
          buttonVariants({ variant: "outline", size: "sm", className: "!h-8" }),
        )}
      >
        <SelectValue placeholder={defaultFilter} />
      </SelectTrigger>
      <SelectContent>
        {options.map((item) => (
          <SelectItem key={item} value={item}>
            {item}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export type GroupCollections = "all" | "only-templates";

export const CollectionFilterSelect = ({
  defaultFilter,
  options,
}: {
  defaultFilter: GroupCollections;
  options: Array<{ label: string; value: string }>;
}) => {
  const navigate = useNavigate();

  return (
    <Select
      onValueChange={(value) => {
        navigate({
          search: (old) => {
            return {
              ...old,
              cacheBuster: value === "Random" ? Math.random() : undefined,
              groupFilter: value,
            };
          },
          params: (old) => {
            return {
              ...old,
            };
          },
        });
      }}
    >
      <SelectTrigger
        className={cn(buttonVariants({ variant: "outline", size: "sm" }))}
      >
        <SelectValue
          placeholder={
            options.find((i) => i.value === defaultFilter)?.label || "All Saved"
          }
        />
      </SelectTrigger>
      <SelectContent>
        {options.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

// export const OrderFilterSelect = ({
//   defaultFilter,
//   options,
// }: {
//   defaultFilter: string;
//   options: Array<string>;
// }) => {
//   const navigate = useNavigate();
//
//   type OrderType =
//     | z.infer<typeof OrderFilterTypes>
//     | z.infer<typeof landerFeedOrderFilter>;
//   return (
//     <Select
//       onValueChange={(value) => {
//         navigate({
//           search: (old) => {
//             return {
//               ...old,
//               cacheBuster: value === "Random" ? Math.random() : undefined,
//               orderFilter: value as OrderType,
//             };
//           },
//           params: (old) => {
//             return {
//               ...old,
//             };
//           },
//         });
//       }}
//     >
//       <SelectTrigger
//         className={cn(
//           buttonVariants({ variant: "outline", size: "sm", className: "h-8" }),
//         )}
//       >
//         <SelectValue placeholder={defaultFilter} />
//       </SelectTrigger>
//       <SelectContent>
//         {options.map((item) => (
//           <SelectItem key={item} value={item}>
//             {item}
//           </SelectItem>
//         ))}
//       </SelectContent>
//     </Select>
//   );
// };
