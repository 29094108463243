import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { PasswordInput } from "@/components/shadcn/password-input";
import { Input } from "@/components/shadcn/input";
import { Button } from "@/components/shadcn/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@/components/shadcn/label.tsx";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import showToastNotification from "@/hooks/useShowToast";
import { useEffect } from "react";
import usePasswordStrengthCheck from "@/hooks/usePasswordStrengthCheck.tsx";

type SearchParams = {
  plan?: string;
  coupon?: string;
};

export const Route = createFileRoute("/sign-up")({
  component: SignUp,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const plan = search?.plan as string | undefined;
    const coupon = search?.coupon as string | undefined;

    return {
      plan,
      coupon,
    };
  },
});

function SignUp() {
  const { plan, coupon } = Route.useSearch();
  const navigate = useNavigate();

  const {
    passwordColor,
    passwordStrengthLabel,
    passwordStrength,
    handlePasswordChange,
  } = usePasswordStrengthCheck();

  useEffect(() => {
    if (plan) {
      localStorage.setItem("cos_plan", plan);
    }
    if (coupon) {
      localStorage.setItem("cos_coupon", coupon);
    }
  }, []);

  const { mutate: signUpWithEmailAndPasswordWorkOs, isPending } =
    trpc.signUpWithEmailAndPassword.useMutation();

  const { mutate: signUpWithGoogle, isPending: isPendingGoogleUrl } =
    trpc.generateGoogleAuthorizationUrl.useMutation();

  const { mutateAsync: logoutUser } = trpc.logoutUser.useMutation();

  const signupFormSchema = z
    .object({
      firstName: z.string({ required_error: "First Name is required" }),
      lastName: z.string({ required_error: "Last Name is required" }),
      email: z.string({ required_error: "Email address is required" }).email({
        message: "Please enter a valid email address.",
      }),
      password: z
        .string({ required_error: "Password is required" })
        .min(8, {
          message: "Password must be at least 8 characters.",
        })
        .regex(/[a-z]/, {
          message: "Password must contain at least one lowercase letter.",
        })
        .regex(/[A-Z]/, {
          message: "Password must contain at least one uppercase letter.",
        })
        .regex(/\d/, { message: "Password must contain at least one number." }),
    })
    .refine((data) => data.password !== data.email, {
      message: "Password cannot be the same as your email",
      path: ["password"],
    });

  type SignupFormValues = z.infer<typeof signupFormSchema>;

  const defaultValues: Partial<SignupFormValues> = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  };

  const form = useForm<SignupFormValues>({
    resolver: zodResolver(signupFormSchema),
    defaultValues,
  });

  async function onSubmit({
    firstName,
    lastName,
    email,
    password,
  }: SignupFormValues) {
    const signupWithEmailAndPasswordOpts = {
      onSuccess: (data: unknown) => {
        if (data) {
          localStorage.setItem("_wos_user", JSON.stringify(data));
          showToastNotification("success", {
            message: "Account created successfully!",
          });
          navigate({ to: "/verify-email" });
        }
      },
      onError: (error: { message: string }) => {
        showToastNotification("error", {
          message: error.message,
        });
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        signUpWithEmailAndPasswordWorkOs(
          { firstName, lastName, email, password, plan },
          signupWithEmailAndPasswordOpts,
        );
      },
      onError: () => {
        signUpWithEmailAndPasswordWorkOs(
          { firstName, lastName, email, password, plan },
          signupWithEmailAndPasswordOpts,
        );
      },
    });
  }

  const signUpWithGoogleAuth = async () => {
    const signUpWithGoogleOpts = {
      onSuccess: (data: string) => {
        if (data) {
          window.location.href = data;
        }
      },
      onError: (error: { message: string }) => {
        showToastNotification("error", {
          message: error.message,
        });
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
      onError: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
    });
  };

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-center gap-2"}>
            <img
              alt={"Creative OS"}
              src={"cos-logo.png"}
              className={"w-8 h-8"}
            />
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold"
              }
            >
              Create an Account
            </h4>
          </div>
          <div>
            <Button
              variant={"outline"}
              type={"submit"}
              disabled={isPendingGoogleUrl}
              onClick={signUpWithGoogleAuth}
              className={"flex gap-2 items-center justify-center w-full"}
            >
              <img alt={""} src={"/images/google-icon.png"} />
              <span>Sign Up with Google</span>
            </Button>
            <div className={"flex items-center self-stretch gap-3 mt-6"}>
              <span className={"border border-themeborder w-full"} />
              <span>OR</span>
              <span className={"border border-themeborder w-full"} />
            </div>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                <div className={"grid lg:grid-cols-2 gap-6"}>
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem>
                        <Label>First Name</Label>
                        <FormControl>
                          <Input placeholder="First Name" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem>
                        <Label>Last Name</Label>
                        <FormControl>
                          <Input placeholder="Last Name" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Email Address</Label>
                      <FormControl>
                        <Input
                          placeholder="name@example.com"
                          type={"email"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Password</Label>
                      <FormControl>
                        <PasswordInput
                          placeholder="Password"
                          {...field}
                          onChange={(e) => {
                            handlePasswordChange(e);
                            field.onChange(e);
                          }}
                        />
                      </FormControl>
                      {form.getValues("password") && (
                        <div className="text-xs mt-1">
                          Password Strength:{" "}
                          <span
                            style={{ color: passwordColor }}
                            className={`font-bold ${passwordColor ? `text-[${passwordColor}]` : ""}`}
                          >
                            {passwordStrengthLabel}
                          </span>
                        </div>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button
                  disabled={isPending || passwordStrength < 2}
                  type={"submit"}
                  className="w-full"
                >
                  {isPending ? "Creating an account..." : "Signup"}
                </Button>
                <div>
                  <Link
                    to={"/login"}
                    className={"block text-center mt-1.5 text-sm"}
                  >
                    Already have an account?{" "}
                    <span className={"underline font-medium"}>Sign in</span>
                  </Link>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
