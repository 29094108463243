import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { PasswordInput } from "@/components/shadcn/password-input";
import { Button } from "@/components/shadcn/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@/components/shadcn/label.tsx";
import { ArrowLeftIcon } from "lucide-react";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useEffect } from "react";
import showToastNotification from "@/hooks/useShowToast";
import usePasswordStrengthCheck from "@/hooks/usePasswordStrengthCheck.tsx";

type SearchParams = {
  token?: string;
  accessToken?: string;
  email?: string;
};

export const Route = createFileRoute("/reset-password")({
  component: ResetPassword,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const token = search?.token as string;
    const accessToken = search?.accessToken as string;
    const email = search?.email as string;

    return {
      token,
      accessToken,
      email,
    };
  },
});

function ResetPassword() {
  const { token, accessToken, email } = Route.useSearch();

  const isMemberstackUser = !!(accessToken && email);

  const navigate = useNavigate();

  const {
    passwordColor,
    passwordStrengthLabel,
    passwordStrength,
    handlePasswordChange,
  } = usePasswordStrengthCheck();

  const { mutate: setNewPassword, isPending: isPendingSetNewPassword } =
    trpc.setNewPassword.useMutation();

  const { mutate: resetPassword, isPending: isPendingResetPassword } =
    trpc.resetPassword.useMutation();

  const {
    isLoading: authIsLoading,
    isRefetching,
    refetch: refetchUserDetails,
  } = trpc.me.useQuery(null, { enabled: false });

  const resetPasswordFormSchema = z.object({
    token: z.string({ required_error: "Token is required" }),
    password: z
      .string({ required_error: "Password is required" })
      .min(8, {
        message: "Password must be at least 8 characters.",
      })
      .regex(/[a-z]/, {
        message: "Password must contain at least one lowercase letter.",
      })
      .regex(/[A-Z]/, {
        message: "Password must contain at least one uppercase letter.",
      })
      .regex(/\d/, { message: "Password must contain at least one number." }),
  });

  type ResetPasswordFormValues = z.infer<typeof resetPasswordFormSchema>;

  const defaultValues: Partial<ResetPasswordFormValues> = {};

  const form = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues,
  });

  // Extract token from query string to be passed to the endpoint
  useEffect(() => {
    if (token) form.setValue("token", token);
  }, [token]);

  useEffect(() => {
    if (isMemberstackUser) {
      form.setValue("token", accessToken);
      // form.setValue("email", email);
    }
  }, [accessToken, isMemberstackUser]);

  async function onSubmit({ token, password }: ResetPasswordFormValues) {
    try {
      if (isMemberstackUser && email) {
        // Payload for Memberstack users
        const memberstackPayload = {
          password,
          email,
          accessToken: token,
        };

        setNewPassword(memberstackPayload, {
          onSuccess: () => {
            refetchUserDetails().then((authUser) => {
              if (
                typeof localStorage !== "undefined" &&
                !authIsLoading &&
                !isRefetching
              ) {
                localStorage.setItem(
                  "_wos_user",
                  JSON.stringify(authUser.data),
                );
              }

              showToastNotification("success", {
                message: "Password reset successful!",
              });
              navigate({ to: "/feeds/templates" });
            });
          },
          onError: (error) => {
            showToastNotification("error", {
              message: error.message,
            });
          },
        });
      } else {
        // Payload for non-Memberstack users
        const nonMemberStackPayload = {
          newPassword: password,
          token,
        };

        resetPassword(nonMemberStackPayload, {
          onSuccess: () => {
            showToastNotification("success", {
              message: "Password reset successful!",
            });
            navigate({ to: "/feeds/templates" });
          },
          onError: (error) => {
            showToastNotification("error", {
              message: error.message,
            });
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-start gap-2"}>
            <Button
              onClick={() =>
                navigate({
                  to: "/login",
                })
              }
              variant={"ghost"}
              className={"flex gap-1.5 items-center"}
            >
              <ArrowLeftIcon size={"1rem"} />
              <span>Back</span>
            </Button>
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold"
              }
            >
              Reset your password
            </h4>
            {isMemberstackUser && (
              <p>
                We've improved our security. Please update your password. This
                is a one-time process to enhance your account's protection!
              </p>
            )}
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <Label>New Password</Label>
                      <FormControl>
                        <PasswordInput
                          placeholder="Enter New Password"
                          {...field}
                          onChange={(e) => {
                            handlePasswordChange(e);
                            field.onChange(e);
                          }}
                        />
                      </FormControl>
                      {form.getValues("password") && (
                        <div className="text-xs mt-1">
                          Password Strength:{" "}
                          <span
                            style={{ color: passwordColor }}
                            className={`font-bold ${passwordColor ? `text-[${passwordColor}]` : ""}`}
                          >
                            {passwordStrengthLabel}
                          </span>
                        </div>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button
                  disabled={
                    isPendingSetNewPassword ||
                    isPendingResetPassword ||
                    passwordStrength < 2
                  }
                  type={"submit"}
                  className="w-full"
                >
                  {isPendingSetNewPassword || isPendingResetPassword
                    ? "Submitting..."
                    : "Confirm and Reset"}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
