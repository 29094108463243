export const INSPIRATION_TABS = [
  {
    name: "Ads",
    link: "/feeds/inspiration",
  },
  // {
  //   name: "Emails",
  //   link: "/feeds/inspiration/emails",
  // },
  {
    name: "Landers",
    link: "/feeds/inspiration/landing-pages",
  },
];
